<template lang="pug">
  v-card
    v-snackbar(v-model='snackbar.snackbar' :color='snackbar.color' :right="true" :timeout='snackbar.timeout' :top="true")
        | {{ snackbar.text }}
        v-btn(dark='' text='' @click='snackbar.snackbar = false')
          | Close
    v-card-title
        | New card
        v-spacer
            v-icon(aria-label="Close" @click="$emit('close')")
                | mdi-close
    v-card-text
        v-row
            v-col(cols="12" md="12")
                div(ref="card")
            v-col(cols="12" md="12").text-center
                v-btn(color='success', small :loading="loading" @click="create") Create
</template>

<script>
  /* eslint-disable */
  const stripe = Stripe('pk_live_Dfd8kq0KeASDVrcLQWoZazR000xAgaateT')
  const elements = stripe.elements()
  export default {

    data () {
      return {
        card: '',
        loading: false,
        snackbar: {
          color: 'red',
          snackbar: false,
          text: '',
          timeout: 2000,
        },
      }
    },

    mounted () {
        const vm = this
        vm.card = elements.create('card')
        vm.card.mount(this.$refs.card)
    },

    methods: {

      create () {
        const vm = this
        vm.loading = true
        stripe.createPaymentMethod({
          type: 'card',
          card: vm.card,
          billing_details: {
            email: vm.$store.state.users.user.email,
          },
        }).then(function (result) {
          if (result.error) {
            vm.loading = false
            vm.snackbar.text = result.error.message
            vm.snackbar.snackbar = true
          } else {
            // console.log(result)
            vm.$store.dispatch('subscriptions/createcard', result).then(function () {
              vm.$emit('close')
            })
          }
        })
      },

    },

    beforeDestroy () {
      const vm = this
      vm.card.destroy()
      elements.destroy()
    }

  }
</script>
